<!--卡券列表-->
<template>
  <div class="cardList">
    <section class="table-search">
      <div class="search-L">
        <el-select
          v-model="tableParams.cardType"
          placeholder="请选择卡券类型"
          @change="reload"
          clearable
        >
          <el-option
            v-for="item in ObjToOpt(cardTypeObj)"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="search-R">
        <el-button type="primary" @click="onAdd(1)">新增卡券</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
      <el-table-column label="卡券名称" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>
            <span>{{ scope.row.cardName }}</span></span
          >
        </template>
      </el-table-column>
      <el-table-column label="卡券类型" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ cardTypeObj[scope.row.cardType] }}</span>
        </template>
      </el-table-column>
      <el-table-column label="类型描述" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-if="scope.row.cardType == 1 || scope.row.cardType == 4"
            >{{ scope.row.cardTypeNum }}次</span
          >
          <span v-if="scope.row.cardType == 3"
            >{{
              scope.row.minUsePrice > 0
                ? "满" + scope.row.minUsePrice
                : "无门槛"
            }}抵扣{{ scope.row.deductionPrice }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="适用范围" min-width="120">
        <template slot-scope="scope">
          <span>{{ scope.row.rangeLimit || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="可用日期" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <p v-for="(item, index) in scope.row.useDateList" :key="index">
            <span v-if="item.suitWriting">{{ item.suitWriting }};</span>
            <span v-if="item.rangeDate == '00:00-00:00'">全天</span>
            <span v-else>{{ item.rangeDate }}</span>
            <span>可用</span>
            <span v-if="item.continuousMaxHour && item.continuousMaxHour != '0'"
              >{{ item.continuousMaxHour }}小时</span
            >
          </p>
        </template>
      </el-table-column>
      <el-table-column label="有效期" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.availabilityDate || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="价格 / 划线价格" align="center">
        <template slot-scope="scope">
          <span>￥{{ scope.row.preferentialPrice || "0" }}</span>
          <span>&nbsp;/&nbsp;</span>
          <s>￥{{ scope.row.originalPrice || "0" }}</s>
        </template>
      </el-table-column>
      <el-table-column label="备注" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.remark || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="卡券状态" align="center">
        <template slot-scope="scope">
          {{ cardStatusObj[scope.row.cardStatus] || "-" }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" min-width="100">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.cardStatus == 0"
            type="text"
            size="medium"
            @click.stop="onAdd(2, scope.row)"
            >编辑</el-button
          >
          <el-button
            type="text"
            size="medium"
            @click="goConfirmDeleteCard(scope.row)"
            v-if="!scope.row.cardStatus"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="table-footer">
      <el-pagination
        layout="total,sizes, prev, pager, next,jumper"
        background
        :total="total"
        :page-size="tableParams.size"
        @size-change="onSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage"
        @current-change="onCurrentPage"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { ObjToOpt } from "@/utils/utils";
import { cardTypeObj,cardStatusObj } from "@/db/objs";
import { getTableList, goDeleteCard } from "@/api/card/card";

export default {
  data() {
    return {
      ObjToOpt,
      cardTypeObj,
      cardStatusObj,
      currentPage: 1, // 当前页
      total: 0, // 总条数
      tableData: [],
      tableParams: {
        cardType: "",
        page: 1,
        size: 10,
      },
    };
  },
  mounted() {
    this.getTableList(); // 【请求】表格数据
  },
  methods: {
    // 删除卡券
    goDeleteCard(cardId) {
      goDeleteCard({ cardId }).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.getTableList();
        }
      });
    },
    // 二次确认删除卡券
    goConfirmDeleteCard(row) {
      let cardId = row.cardId;
      let tips='请确定是否删除该卡券?'
      if(row.equityCount>0){
        tips='当前卡券已与会员权益赠送卡券相关联，删除后会员将无法领取，是否删除该卡券？'
      }
      this.$confirm(tips, "删除卡券", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 接口
          this.goDeleteCard(cardId);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 【请求】表格数据
    getTableList() {
      let data = this.tableParams;
      getTableList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【监听】编辑
    onAdd(type, row) {
      //  1新增 2编辑
      if (type == 1) {
        this.$router.push("/card/cardEdit");
      } else {
        this.$router.push({
          path: "/card/cardEdit?cardId=" + row.cardId,
        });
      }
    },
    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getTableList();
    },
    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getTableList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
.cardList {
  padding: 0.15rem;
  box-sizing: border-box;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.15rem;
}

.search-L {
  display: flex;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}
</style>
